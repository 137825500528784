export const ROUTES = {
    HOME: "/",
    ADMIN: "/admin/*",
    COFFEE: "/coffee",
    SINGLE_COFFEE: "/coffee/:id",
    ORDER: "/order",
    RESULT: "/result",
    POLICY: "/policy",
    OFFER: "/offer",
    CONTACTS: "/contacts",
    LOGIN: "/auth_admin",
    DRIP: "/drip",
    SINGLE_DRIP: "/drip/:id",
    MERCH: "/merch",
    SINGLE_MERCH: "/merch/:id",
    LEMONADE: "/lemonade",
    COURSE: "/course",
    SINGLE_LEMONADE: "/lemonade/:id",
};
